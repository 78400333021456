import 'tailwindcss/tailwind.css';
import AppRoutes from './approutes/AppRoutes';

function App() {
  return (
    <>
      <AppRoutes />
    </>
  );
}

export default App;
